import { buildQueryParamsAuth, getResData, httpClient } from "utils";

export const ALLOWED_VALUES = {
  DICTIONARY: `/dict`,
  SITES_BY_COUNTRY:(countryName:any)=>`/dict/sites?country=${countryName}`,
  COUNTRY_BY_COUNTRY_REGION:(countryRegionName:any)=>`/dict/countries?country_region=${countryRegionName}`
};

export const MFA_VALUES = {
  GET_MFA_VALUE: `/auth0`,
  SET_MFA_VALUE: (query?: any) => {
    const baseUrl = "/auth0";

    return `${baseUrl}?${buildQueryParamsAuth(query)}`;
  },
};

export interface UnitDescription {
  unit: string;
  description: string;
}

export interface UtilityType {
  allowedUnits: string[];
  id: number;
  name: string;
  unitDescription: UnitDescription[];
}

export interface UtilitySubtypesMap {
  [utilityType: string]: string[];
}

export interface AllowedValuesDTO {
  billStatuses: string[];
  countries: string[];
  currencies: string[];
  frequencies: string[];
  regions: string[];
  siteStatuses: string[];
  siteTypes: string[];
  units: string[];
  uploadTypes: string[];
  utilitySubtypesMap: UtilitySubtypesMap;
  utilityTypes: UtilityType[];
  countryRegions: string[];
  intensityCategories: string[];
  intensityAreaUnits: string[];
}

export const getAllowedValues = (): Promise<AllowedValuesDTO> =>
  httpClient.get<AllowedValuesDTO>(ALLOWED_VALUES.DICTIONARY).then(getResData);

export const getMFAValues = (): Promise<any> =>
  httpClient.get<any>(MFA_VALUES.GET_MFA_VALUE).then(getResData);

export const setMFAValues = (query: any): Promise<any> =>
  httpClient.put<any>(MFA_VALUES.SET_MFA_VALUE(query)).then(getResData);

export const getSitesByCountry = (name:any): Promise<any> =>
  httpClient.get<any>(ALLOWED_VALUES.SITES_BY_COUNTRY(name)).then(getResData);

export const getCountryByRegion = (regionName:any): Promise<any> =>
  httpClient.get<any>(ALLOWED_VALUES.COUNTRY_BY_COUNTRY_REGION(regionName)).then(getResData);
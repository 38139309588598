import { Box } from "@mui/material";
import MaterialReactTable, {
  MRT_RowSelectionState,
  MRT_SortingState,
} from "material-react-table";
import React, { useContext } from "react";

import {
  DocumentsUpload,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2,
  Loading,
  MarginWrapper,
} from "components";

import { useDocumentScope } from "hooks";

import { Scope3Context } from "pages/authenticated/router";

import { checkIsScope3 } from "utils";

import { Actions } from "./components/actions";
import { SCOPE_DOCUMENTS_COLUMNS } from "./components/table/columns";

export const DocumentUploadPageScope3 = () => {
  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );
  const [sorting, setSorting] = React.useState<MRT_SortingState>([]);

  const { activeScope } = useContext(Scope3Context);
  const isScope3 = checkIsScope3(activeScope);

  const handleRowSelection = () => {
    setRowSelection({});
  };

  const columns = SCOPE_DOCUMENTS_COLUMNS;

  const { data, isLoading } = useDocumentScope({
    isScope3,
  });
  const tableRows = data?.documentsScope3 || [];

  return (
    <MarginWrapper>
      <Box sx={FILTERS_AND_ACTIONS_WRAPPER}>
        <Box
          sx={{
            pt: 2,
          }}
        >
          <DocumentsUpload isScope3 />
        </Box>
        <Box sx={FLEX_ALIGN_CENTER_GAP_2} />

        <Box sx={FLEX_ALIGN_CENTER_GAP_2}>
          <Actions
            rowSelection={rowSelection}
            setrowSelection={handleRowSelection}
          />
        </Box>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <MaterialReactTable
          columns={columns}
          muiTableBodyCellProps={{
            sx: {
              backgroundColor: 'white',
            },
          }}
          data={tableRows}
          enableRowSelection
          enablePagination={false}
          getRowId={(row) => row.id}
          enableColumnResizing
          enableDensityToggle={false}
          enableStickyHeader
          initialState={{ density: "compact" }}
          onSortingChange={setSorting}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection, isLoading, sorting }}
          enableRowVirtualization
        />
      )}
    </MarginWrapper>
  );
};

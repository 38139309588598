import { Box } from "@mui/material";
import type { CellContext } from "@tanstack/table-core";
import dayjs from "dayjs";
import React, { FunctionComponent, useState, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import { AdaptedCollectorsMissingDocuments, MonthUsage } from "api";

import { EMPTY_CELL_VALUE } from "components";

import { ReactChildren } from "types";

import { formatNumber, isNullOrUndefined } from "utils";

import { AddBillModal } from "../AddBillModal";

import { MISSING_DOCUMENTS_PAGE_QUERY_PARAMS } from "../../consts";

const COMMON_STYLES = {
  height: "60px",
  display: "flex",
  alignItems: "center",
  p: 2,
  cursor: "pointer",
};

const CELL_COLORS = {
  error: {
    backgroundColor: "error.light",
    color: "error.main",
  },
  warning: {
    backgroundColor: "warning.light",
    color: "warning.main",
  },
  selected: {
    backgroundColor: "gray",
    color: "gray.main",
  },
  deactivated: {
    backgroundColor: "#dddddd",
    color: "#dddddd",
  },
  none: {},
  info: {
    backgroundColor: "#BFDBFE",
    color: "#000000",
  },
};

export const DateWrapper: FunctionComponent<
  {
    color?: "error" | "warning" | "selected" | "none" | "deactivated" | "info";
    onClick?: () => void;
  } & ReactChildren
> = ({ color = "none", children, onClick }) => (
  <Box
    sx={{
      ...COMMON_STYLES,
      ...CELL_COLORS[color],
    }}
    onClick={onClick}
  >
    {children}
  </Box>
);

export const MonthCell = ({
  getValue,
  row,
  cell,
}: CellContext<AdaptedCollectorsMissingDocuments, unknown>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = getValue() as MonthUsage;
  const { enabled, disableDate } = row.original.collector;
  const [showBillModel, setShowBillModel] = useState(false);
  const columnId = cell.column.id;
  const [Year, Month] = columnId.split("-");
  const columnYear = parseInt(Year, 10);
  const columnMonth = parseInt(Month, 10);

  const isCurrentCellSelected =
    searchParams.get(MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.COLLECTOR_ID) ===
      String(row.original.id) &&
    searchParams.get(MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.MONTH) ===
      cell.column.id;

  const disableDateMonth = useMemo(() => dayjs(disableDate).utc().get("month"), [disableDate]);
  const disableDateYear = useMemo(() => dayjs(disableDate).utc().get("year"), [disableDate]);

  const onClick = () => {
    const queryParams = Object.fromEntries(searchParams.entries());
    if (isCurrentCellSelected) {
      delete queryParams[MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.COLLECTOR_ID];
      delete queryParams[MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.MONTH];
      setSearchParams(createSearchParams(queryParams));
    } else {
      setSearchParams(
        createSearchParams({
          ...queryParams,
          [MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.COLLECTOR_ID]: String(
            row.original.id,
          ),
          [MISSING_DOCUMENTS_PAGE_QUERY_PARAMS.MONTH]: cell.column.id,
        }),
      );
    }
  };

  const onClick123 = () => {
    setShowBillModel(true);
  };

  if (value && isNullOrUndefined(value.usage)) {
    if (
      columnYear < disableDateYear ||
      (columnYear === disableDateYear && columnMonth < disableDateMonth + 1)
    ) {
      return (
        <>
          <DateWrapper
            color={isCurrentCellSelected ? "selected" : "error"}
            onClick={
              isCurrentCellSelected
                ? onClick123
                : !enabled
                ? undefined
                : onClick123
            }
          >
            {EMPTY_CELL_VALUE}
          </DateWrapper>
          <AddBillModal
            isOpen={showBillModel}
            collectorId={row?.original?.id}
            onClose={() => setShowBillModel(false)}
          />
        </>
      );
    }
    if (
      columnYear > disableDateYear ||
      (columnYear === disableDateYear && columnMonth >= disableDateMonth + 1)
    ) {
      return (
        <>
          <DateWrapper
            color={!enabled ? "deactivated" : "error"}
            onClick={!enabled ? undefined : onClick123}
          >
            {EMPTY_CELL_VALUE}
          </DateWrapper>
          <AddBillModal
            isOpen={showBillModel}
            collectorId={row?.original?.id}
            onClose={() => setShowBillModel(false)}
          />
        </>
      );
    }
    return (
      <>
        <DateWrapper
          color={
            isCurrentCellSelected
              ? "selected"
              : !enabled
              ? "deactivated"
              : "error"
          }
          onClick={onClick123}
        >
          {EMPTY_CELL_VALUE}
        </DateWrapper>
        <AddBillModal
          isOpen={showBillModel}
          collectorId={row?.original?.id}
          onClose={() => setShowBillModel(false)}
        />
      </>
    );
  }

  if (value.hasNullUsage) {
    if (
      columnYear < disableDateYear ||
      (columnYear === disableDateYear && columnMonth < disableDateMonth + 1)
    ) {
      return (
        <DateWrapper
          color={isCurrentCellSelected ? "selected" : "warning"}
          onClick={onClick}
        >
          {formatNumber(Number(value.usage), 4)!}
        </DateWrapper>
      );
    }
    if (
      columnYear > disableDateYear ||
      (columnYear === disableDateYear && columnMonth >= disableDateMonth + 1)
    ) {
      return (
        <DateWrapper
          color={
            !enabled
              ? value.usage! !== null && value.usage! !== 0
                ? "warning"
                : "deactivated"
              : "warning"
          }
          onClick={
            !enabled
              ? value.usage! !== null && value.usage! !== 0
                ? onClick
                : undefined
              : onClick
          }
        >
         {formatNumber(Number(value.usage), 4)!}
        </DateWrapper>
      );
    }

    return (
      <DateWrapper
        color={isCurrentCellSelected ? "selected" : "warning"}
        onClick={onClick}
      >
        {value.usage !== undefined
          ? typeof value.usage === "number"
            ? value.usage.toString().includes(".")
              ? value.usage
                  .toString()
                  .slice(0, value.usage.toString().indexOf(".") + 5)
              : value.usage!
            : value.usage!
          : value.usage!}
      </DateWrapper>
    );
  }

  if (
    columnYear < disableDateYear ||
    (columnYear === disableDateYear && columnMonth < disableDateMonth + 1)
  ) {
    return (
      <DateWrapper
        color={isCurrentCellSelected ? "selected" : "none"}
        onClick={onClick}
      >
        {formatNumber(Number(value.usage), 4)!}
      </DateWrapper>
    );
  }
  if (
    columnYear > disableDateYear ||
    (columnYear === disableDateYear && columnMonth > disableDateMonth + 1)
  ) {
    return (
      <DateWrapper
        color={
          !enabled
            ? value.usage! !== null && value.usage! !== 0
              ? "none"
              : "deactivated"
            : "none"
        }
        onClick={
          !enabled
            ? value.usage! !== null && value.usage! !== 0
              ? onClick
              : undefined
            : onClick
        }
      >
        {formatNumber(Number(value.usage), 4)!}
      </DateWrapper>
    );
  }

  if (
    (value !== null && value.billType === "INTERPOLATION") ||
    (value !== null && value.billType === "EXTRAPOLATION")
  ) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "60px",
          backgroundColor: "#BFDBFE",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          p: 2,
        }}
        onClick={
          isCurrentCellSelected
            ? onClick
            : !enabled
            ? value.usage! !== null && value.usage! !== 0
              ? onClick
              : undefined
            : onClick
        }
      >
        {formatNumber(Number(value.usage), 4)!}
      </Box>
    );
  }
  return (
    <DateWrapper
      color={
        isCurrentCellSelected
          ? "selected"
          : !enabled
          ? value.usage! !== null && value.usage! !== 0
            ? "none"
            : "deactivated"
          : "none"
      }
      onClick={
        isCurrentCellSelected
          ? onClick
          : !enabled
          ? value.usage! !== null && value.usage! !== 0
            ? onClick
            : undefined
          : onClick
      }
    >
      {formatNumber(Number(value.usage), 4)!}
    </DateWrapper>
  );
};

export const DayCell = ({
  getValue,
}: CellContext<AdaptedCollectorsMissingDocuments, unknown>) => {
  const value = getValue() as string;

  if (isNullOrUndefined(value)) {
    return <DateWrapper color="error">{EMPTY_CELL_VALUE}</DateWrapper>;
  }

  return <DateWrapper color="none">{value}</DateWrapper>;
};

export const DayCellMissingMonth = ({
  getValue,
}: CellContext<AdaptedCollectorsMissingDocuments, unknown>) => {
  const value = getValue() as string;
  const numericValue = parseFloat(value);
  // eslint-disable-next-line no-restricted-globals
  const formattedValue = isNaN(numericValue) ? value : formatNumber(numericValue, 4);

  if (isNullOrUndefined(value)) {
    return <DateWrapper color="error">{EMPTY_CELL_VALUE}</DateWrapper>;
  }

  return <DateWrapper color="none">{formattedValue}</DateWrapper>;
};

import { Box, Typography } from "@mui/material";
import MaterialReactTable from "material-react-table";
import React from "react";

import {
  EndYearMonthPeriodFilter,
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2,
  Label18SemiBold,
  Loading,
  MarginWrapper,
  StartYearMonthPeriodFilter,
  useYearMonthPeriodFilterScope3,
} from "components";

import { useScop3SitesEmissions } from "hooks";

import { useNewBreadcrumb } from "recoils";

import { ConsumptionSummary } from "./components";
import { EmissionActions } from "./components/actions";
import { SITE_EMISSIONS_COLUMNS_DETAILS } from "./components/site-table/index";
import { SITES_EMISSIONS_PAGE_SCOPE3 } from "./consts";

export const SitesEmissionsPageScope3 = () => {
  useNewBreadcrumb(SITES_EMISSIONS_PAGE_SCOPE3.TITLE);
  const {
    period,
    endPeriod,
    startDate,
    endDate,
    onPeriodChange,
    onEndPeriodChange,
    onPeriodAccept,
    onEndPeriodAccept,
    availableYears,
  } = useYearMonthPeriodFilterScope3({ withQueryParams: false });

  const { data: sitesEmissionsScop3, isLoading } = useScop3SitesEmissions({
    start_date: startDate,
    end_date: endDate,
  });

  const tableRows = sitesEmissionsScop3?.emission || [];

  const columnsMatched = SITE_EMISSIONS_COLUMNS_DETAILS;

  // const tableMatched = useReactTable({
  //   data: tableRows,
  //   columns: columnsMatched,
  //   columnResizeMode: "onChange",
  //   getCoreRowModel: getCoreRowModel(),
  //   enableSorting: false,
  // });

  return (
    <MarginWrapper>
      <Box sx={FILTERS_AND_ACTIONS_WRAPPER}>
        <Box sx={FLEX_ALIGN_CENTER_GAP_2}>
          <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
            <Box sx={{ width: "160px" }}>
              <StartYearMonthPeriodFilter
                period={period}
                label="Start Date"
                onPeriodChange={onPeriodChange}
                onPeriodAccept={onPeriodAccept}
                availableYears={availableYears}
              />
            </Box>

            <Box sx={{ width: "180px" }}>
              <EndYearMonthPeriodFilter
                period={endPeriod}
                label="End Date"
                onEndPeriodChange={onEndPeriodChange}
                onEndPeriodAccept={onEndPeriodAccept}
                availableYears={availableYears}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={FLEX_ALIGN_CENTER_GAP_2}>
          <EmissionActions csvData={tableRows} />
        </Box>
      </Box>

      <Label18SemiBold sx={{ ml: 2, mb: 2 }}>Consumption Data</Label18SemiBold>
      {sitesEmissionsScop3 && !isLoading && (
        <Box sx={{ mb: 2 }}>
          <ConsumptionSummary sitesEmissions={sitesEmissionsScop3} />
        </Box>
      )}

      <Box sx={{ width: "100%", typography: "body1" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <MaterialReactTable
            columns={columnsMatched}
            muiTableBodyCellProps={{
              sx: {
                backgroundColor: 'white',
              },
            }}
            data={tableRows}
            enableRowSelection
            enablePagination={false}
            enableRowVirtualization
            renderBottomToolbarCustomActions={() => (
              <Typography>
                Fetched {sitesEmissionsScop3?.count} of{" "}
                {sitesEmissionsScop3?.count} total rows.
              </Typography>
            )}
          />
        )}
      </Box>
    </MarginWrapper>
  );
};

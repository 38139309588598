import { ScoprDocumentsQuery } from "api";

import { MaybeNull } from "types";

import { buildQueryParams, getResData, httpClient } from "utils";

export const EMISSION_FACTORS_API_PATHS = {
  EMISSION_FACTORS: "/utility-type-conversion",
  UPLOAD_EMISSION_FACTORS: "/upload",
  MANUAL_SEARCH_DROPDOWN_DATA: "/ecoinvent/parameters",
  EMISSION_FACTORS_POPUP: (documentId: string) =>
    `/supplies/${documentId}/possiblematches`,
  EMISSION_FACTORS_POPUP_COMPONENT: (documentId: string, componentId: string) =>
    `/supplies/${documentId}/${componentId}/possiblematches`,
  SUPPLIES_RECALCULATE: () => `/supplies/recalculate`,
  SUPPLIES_MANUAL_SEARCH: () => `/supplies/manualsearch`,
  SUPPLIES_COMPONENT_RECALCULATE: () => `/supplies/component/recalculate`,

  PENDING_SCOPE_EMISSION_FACTOR: (query?: ScoprDocumentsQuery) => {
    const baseUrl = "/supplies";

    if (!query) {
      return baseUrl;
    }

    return `${baseUrl}?${buildQueryParams(query)}`;
  },
};

export const SCIENTIFIC_UNIT_API_PATHS = {
  SCIENTIFIC_UNIT: "/unitconversions",
  UPLOAD_EMISSION_FACTORS: "/upload",
  MANUAL_SEARCH_DROPDOWN_DATA: "/ecoinvent/parameters",
  EMISSION_FACTORS_POPUP: (documentId: string) =>
    `/supplies/${documentId}/possiblematches`,
  EMISSION_FACTORS_POPUP_COMPONENT: (documentId: string, componentId: string) =>
    `/supplies/${documentId}/${componentId}/possiblematches`,
  SUPPLIES_RECALCULATE: () => `/supplies/recalculate`,
  SUPPLIES_MANUAL_SEARCH: () => `/supplies/manualsearch`,
  SUPPLIES_COMPONENT_RECALCULATE: () => `/supplies/component/recalculate`,

  PENDING_SCOPE_EMISSION_FACTOR: (query?: ScoprDocumentsQuery) => {
    const baseUrl = "/supplies";

    if (!query) {
      return baseUrl;
    }

    return `${baseUrl}?${buildQueryParams(query)}`;
  },
};

export interface EmissionFactorDTO {
  ch4FossilEmissionFactorRatio: number;
  ch4FossilEmissionFactorUnit: string;
  ch4NonFossilEmissionFactorRatio: MaybeNull<number>;
  ch4NonFossilEmissionFactorUnit:MaybeNull<string>;
  co2ConversionRatio: number;
  emissionFactorType: string;
  co2EmissionFactorRatio: number;
  co2EmissionFactorUnit: string;
  id: number;
  measurementUnit: string;
  n2oEmissionFactorRatio: number;
  n2oEmissionFactorUnit: string;
  region: MaybeNull<string>;
  source: string;
  utilityTypeName: string;
  validFrom: MaybeNull<number>;
  validTo: MaybeNull<number>;
  utilitySubtype: MaybeNull<string>;
  gwp: MaybeNull<number>;
  gwpMultipliers:MaybeNull<number>;
  gwpSource:MaybeNull<string>;
  co2ConversionUnit:MaybeNull<string>;
  sourceUpdateDate:MaybeNull<string>;
  esgFloAccessDate:MaybeNull<string>;
  sourceLink:MaybeNull<string>;
}
export interface ReferencesDTO {
  fromCurrency: string;
  toCurrency: string;
  conversionValue: number;
  updatedDate:string;
  utilityType: string;
  utilitySubtype: string;
  fromUnit: string;
  toUnit:string;
  "": string;
 }

export const getEmissionFactors = (): Promise<EmissionFactorDTO[]> =>
  httpClient
    .get<EmissionFactorDTO[]>(EMISSION_FACTORS_API_PATHS.EMISSION_FACTORS)
    .then(getResData);
    
    export const getScientificUnit = (): Promise<ReferencesDTO[]> =>
  httpClient
    .get<ReferencesDTO[]>(SCIENTIFIC_UNIT_API_PATHS.SCIENTIFIC_UNIT)
    .then(getResData);

export const uploadEmissionFactors = (file: FormData) =>
  httpClient.post(EMISSION_FACTORS_API_PATHS.UPLOAD_EMISSION_FACTORS, file);

export const getScopePendingEmissionFactors = (
  query: ScoprDocumentsQuery,
): Promise<any> =>
  httpClient
    .get<any>(EMISSION_FACTORS_API_PATHS.PENDING_SCOPE_EMISSION_FACTOR(query))
    .then(getResData);

export const getScopePendingpopupEmissionFactors = (
  documentId: string,
): Promise<any> =>
  httpClient
    .get<any>(EMISSION_FACTORS_API_PATHS.EMISSION_FACTORS_POPUP(documentId))
    .then(getResData);

export const getScopePendingConponentpopupEmissionFactors = (
  documentId: string,
  componentId: string,
): Promise<any> =>
  httpClient
    .get<any>(
      EMISSION_FACTORS_API_PATHS.EMISSION_FACTORS_POPUP_COMPONENT(
        documentId,
        componentId,
      ),
    )
    .then(getResData);

export const getScopePendingManualDropdownDataEmissionFactors =
  (): Promise<any> =>
    httpClient
      .get<any>(EMISSION_FACTORS_API_PATHS.MANUAL_SEARCH_DROPDOWN_DATA)
      .then(getResData);

export const recalculateSupplies = (body: any) =>
  httpClient
    .post(EMISSION_FACTORS_API_PATHS.SUPPLIES_RECALCULATE(), body)
    .then(getResData);

export const supplieRecalculateSupplies = (body: any) =>
  httpClient
    .post(EMISSION_FACTORS_API_PATHS.SUPPLIES_COMPONENT_RECALCULATE(), body)
    .then(getResData);

export const manualSearchSupplies = (body: any) =>
  httpClient
    .post(EMISSION_FACTORS_API_PATHS.SUPPLIES_MANUAL_SEARCH(), body)
    .then(getResData);

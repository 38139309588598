import { buildQueryParams } from "utils";


export const REC_API_PATHS = {
  RECsDOCUMENT: (query?: any) => {
    const baseUrl = "/recdocument";

    if (!query) {
      return baseUrl;
    }

    return `${baseUrl}?${buildQueryParams(query)}`;
  },
  RECsDOCUMENT_UPLOADED: "/recdocument/file-uploaded",

  RECsDOCUMENT_DETAILS: (recId?: any) => {
    const baseUrl = "/recdocument";

    if (!recId) {
      return baseUrl;
    }

    return `${baseUrl}/${(recId)}`;
  }
  
};

import { Box, SxProps, Tab, Tabs as TabsMui, Theme } from "@mui/material";
import React, { FunctionComponent, ReactElement, ReactNode } from "react";
import { Link, Outlet } from "react-router-dom";

import { useRouteMatch } from "./hooks";

export const RoutedTabs: FunctionComponent<{
  tabs: {
    icon?: ReactElement;
    label: ReactNode;
    to: string;
    pattern?: string;
    disable?: boolean;
  }[];
  sx?: SxProps<Theme>;
}> = ({ tabs, sx }) => {
  const routeMatch = useRouteMatch(tabs.map((t) => t.pattern || t.to));
  const currentTab:any = routeMatch?.pathnameBase;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabsMui value={currentTab} variant="fullWidth">
          {tabs
            .filter((tab) => !tab.disable)
            .map((tab) => (
              <Tab
                key={`tab-${tab.to}`}
                icon={tab.icon}
                label={tab.label}
                iconPosition="start"
                sx={{ minHeight: "48px", ...sx }}
                value={tab.to}
                component={Link}
                to={tab.to}
              />
            ))}
        </TabsMui>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Outlet />
      </Box>
    </Box>
  );
};

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getFiles, deleteFiles, FilesRepoDTO, updatedFolder } from 'api'
import { DeleteDialogIntensity, DocumentsUploadFileRepo, MarginWrapper, useDialogControls } from 'components'
import React, { useEffect, useRef, useState } from 'react'
import { Typography, IconButton, Divider, Tooltip, Box, Stack, Button, TextField } from '@mui/material';
import { ArrowdownIcon, EditIcon, Pencil, TrashIcon, UndoIcon } from 'assets/icons';
import MaterialReactTable from 'material-react-table';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNotify } from 'hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { useNewBreadcrumb } from 'recoils';

import { downloadFileRepo } from 'utils';
import { deleteFolder, downloadFiles, undoFile, undoFiles } from 'api/filesrepo';
import { QUERY_KEYS } from 'consts';
import { useFolders } from 'hooks/api/filesrepo';
import { AddFilesRepoModal } from '../sitesv2/components/site-editor-form/components/AddFilesRepoModal';

import { FILES_COLUMNS } from './components';
import { EMISSION_FACTORS_FILES } from './consts';

// Dropdown menu style
const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

// End dropdown style
const useOutsideClick = (ref: any) => {
    const [outSideClick, setOutsideClick] = useState<any>();

    useEffect(() => {
        const handleClickOutside = (e: { target: any; }) => {
            if (!ref?.current?.contains(e.target) || ref?.current === null) {
                setOutsideClick(true);
            } else {
                setOutsideClick(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
    return outSideClick;
};

const FoldersPage = () => {

    //* ---- Hook
    useNewBreadcrumb(EMISSION_FACTORS_FILES.TITLE);
    const navigate = useNavigate();
    const { id } = useParams();
    const deleteDialogControls = useDialogControls();
    const { data: folderAllData }: any = useFolders();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const editRef = useRef(null);
    const ClickedOutside = useOutsideClick(editRef);

    //* ---- States
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [addFileModalOpen, setAddFileModalOpen] = useState(false);
    const [EditFiles, setEditFiles] = useState<any | undefined>();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [folderNameEdit, setFolderNameEdit] = useState('');
    const [toDeleteFiles, setDeleteFiles] = React.useState<any | undefined>();
    const [uploadAPI, setUploadAPI] = useState(false);

    //* ---- Const
    const open = Boolean(anchorEl);
    const columns = FILES_COLUMNS;
    const numericId: number | undefined = id ? parseInt(id, 10) : undefined;
    const folderNames = folderAllData?.map((item: { folderName: any; id: number | undefined; name: any }) => {
        if (item.id === numericId) {
            return item.folderName;
        }
        return null;
    })
        .filter((folderName: any) => folderName !== null);
    const array = folderNames
    const value = array?.[0];


    //* ---- API's
    const { mutateAsync: deleteFolderRequest } = useMutation(deleteFolder);
    const { mutateAsync: deleteFilesRequest } = useMutation(deleteFiles);
    const { mutateAsync: undoFilesRequest } = useMutation(undoFiles);
    const { mutateAsync: undoFileRequest } = useMutation(undoFile);
    const { mutateAsync: updateFolderRequest } = useMutation(updatedFolder);
    const { data, mutate } = useMutation(getFiles);
    const ReferencesData: FilesRepoDTO[] = data ?? []
    const idFolder = ReferencesData?.map((item) => item.id)

    //* ---- Use Effect's
    useEffect(() => {
        if (value !== undefined) {
            setFolderNameEdit(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (ClickedOutside) {
            handleEditFolder()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ClickedOutside]);

    useEffect(() => {
        if (uploadAPI === true) {
            if (numericId !== undefined) {
                mutate(numericId);
            }
        } else if (numericId) {
            mutate(numericId);
        }

    }, [uploadAPI, numericId, mutate])

    //* ---- Page Functions
    const onDownloadClick = (fileId: any) => {
        downloadFileRepo(() => downloadFiles(fileId), "documents.zip")
            .then(() => {
                notify.success("Folder has been successfully downloaded");
            })
            .catch(() => {
                if (fileId.length >= 2) {
                    notify.error(
                        "Download failed due to duplicate files.",
                    );
                } else {
                    notify.error("Folder download unsuccessful. Please try again!");
                }
            });
    };

    const handleEditFolder = () => {
        if (folderNameEdit !== '' && folderNameEdit !== value) {
            handleCreateFolder(folderNameEdit);
        } else {
            setIsDialogOpen(false)
        }
    };

    const onDownloadFileClick = (fileId: any) => {
        downloadFileRepo(() => downloadFiles(fileId), "documents.zip")
            .then(() => {
                notify.success("File has been successfully downloaded");
            })
            .catch(() => {
                if (fileId.length >= 2) {
                    notify.error(
                        "Download failed due to duplicate files",
                    );
                } else {
                    notify.error("File download failed. Please check your internet connection and try again.");
                }
            });
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const Undo = (onUndoId: any) => {
        const handleClickUndo = () => {
            undoFilesRequest(onUndoId?.onUndo, {
                onError: () => {
                    notify.error(`Some error  undo the  folder!`);
                },
                onSuccess: () => {
                },
            }).then((res) => {
                if (res?.data) {
                    queryClient.invalidateQueries([QUERY_KEYS.FILESREPO])
                    notify.success(` Successfully restored folder!`);
                    toast.dismiss();
                }
            })
        };

        return (
            <Box sx={{ height: "100%", width: "100%", backgroundColor: "#FFFAEA", paddingLeft: '10px' }}>
                <Box sx={{ display: "flex", flexDirection: "row", marginRight: "80px" }} >
                    <Typography sx={{ mt: "3px", mr: "10px", height: "15px", width: "15px" }}><UndoIcon /></Typography>
                    <Typography sx={{ color: "#926E00", ml: 1.5 }}>

                        {folderNames} has been deleted!
                    </Typography>

                </Box>
                <div style={{ fontFamily: "Manrope", position: 'absolute', top: '0', bottom: '0', right: '0', display: 'flex', alignItems: 'center', backgroundColor: "#F0DEBC", borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
                    <Button sx={{ color: "#7E5F00", fontFamily: "Manrope" }} onClick={handleClickUndo}>UNDO</Button>
                </div>
            </Box>

        );
    }

    // eslint-disable-next-line react/no-unstable-nested-components
    const UndoFile = (onUndoId: any) => {
        const handleClickUndo = () => {
            undoFileRequest(onUndoId?.onUndo, {
                onError: () => {
                    toast.dismiss();
                    notify.error(`Some error  undo the file!`);
                },
                onSuccess: () => {
                },
            }).then((res) => {
                if (res?.data) {
                    notify.success(` Successfully restored file!`);
                    toast.dismiss();
                    if (numericId !== undefined) {
                        mutate(numericId);
                    }
                }
            })
        };


        return (
            <Box sx={{ height: "100%", width: "100%", backgroundColor: "#FFFAEA", paddingLeft: '10px' }}>
                <Box sx={{ display: "flex", flexDirection: "row", marginRight: "80px" }} >
                    <Typography sx={{ mt: "3px", mr: "10px", height: "15px", width: "15px" }}><UndoIcon /></Typography>
                    <Typography sx={{ color: "#926E00", ml: 1.5 }}>
                        {onUndoId?.filename} has been deleted!
                    </Typography>

                </Box>
                <div style={{ fontFamily: "Manrope", position: 'absolute', top: '0', bottom: '0', right: '0', display: 'flex', alignItems: 'center', backgroundColor: "#F0DEBC", borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
                    <Button sx={{ color: "#7E5F00", fontFamily: "Manrope" }} onClick={handleClickUndo} >UNDO</Button>
                </div>
            </Box>

        );
    }

    const handleDeleteFolder = (filesId: number) => {
        deleteFolderRequest(filesId, {
            onError: () => {
                deleteDialogControls.close();
                notify.error(`Folder deletion unsuccessful. Please ensure that the folder is not in use and try again.`);
                handleClose();
            },
            onSuccess: () => {
                handleClose();
                toast(<Undo onUndo={numericId || 0} />, { closeButton: false, hideProgressBar: true, style: { padding: '0px', backgroundColor: "#FFFAEA" }, })
                navigate('/references/files');
            },
        });
    }

    const successApi = () => {
        if (numericId !== undefined) {
            mutate(numericId);
        }
    };

    const handleApiResponse = (e: any) => {
        setUploadAPI(e)
    }

    const handleApiResponseGet = (fileValue: any) => {
        if (fileValue) {
            if (numericId !== undefined) {
                mutate(numericId);
            }
        }
    }

    const handleEditFile = (rowData: any) => {
        setAddFileModalOpen(true);
        setEditFiles(rowData);
    };

    const handleDeleteFiles = (fileValue: any) => {
        if (fileValue.id) {

            deleteFilesRequest(fileValue.id, {
                onError: () => {

                    notify.error(`File deletion unsuccessful. Please try again!`);
                    deleteDialogControls.close()
                },
                onSuccess: () => {
                    toast(<UndoFile onUndo={fileValue.id} filename={fileValue.documentFileName} />, { closeButton: false, hideProgressBar: true, style: { padding: '0px', backgroundColor: "#FFFAEA" }, })
                    if (numericId !== undefined) {
                        mutate(numericId);
                    }
                    deleteDialogControls.close()
                },
            });

        }
    };

    const createFolder = () => {
        setIsDialogOpen(true)
    };

    const handleCreateFolder = (folder: string) => {
        let updatedFolderData: any;
        if (folder) {
            updatedFolderData = {
                id: numericId,
                folderName: folder,

            };
            updateFolderRequest(updatedFolderData, {
                onError: () => {
                    setIsDialogOpen(false);
                    notify.error(`Unable to rename folder. Please ensure that it's not in use and try again.`);
                },
                onSuccess: () => {
                    setIsDialogOpen(false);
                    if (numericId !== undefined) {
                        getFiles(numericId);
                        queryClient.invalidateQueries([QUERY_KEYS.FILESREPO])
                    }
                },
            });

        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseFileModal = () => {
        if (addFileModalOpen) {
            setAddFileModalOpen(false);
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <MarginWrapper>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <IconButton
                        color="primary"
                        size="large"
                        onClick={handleGoBack}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontFamily: 'Manrope', fontSize: '16px', height: '22px', width: '172' }}
                    >
                        Back to folder
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", mr: 5 }}>

                    <Button
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{
                            backgroundColor: "#FFFFFF", color: "#1F2937", '&:hover': {
                                backgroundColor: "#FFFFFF",
                                color: "#1F2937",
                            },
                        }}
                    >
                        Actions
                    </Button>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <DocumentsUploadFileRepo apiResponse={handleApiResponse} folderId={numericId} getApiCall={handleApiResponseGet} getmenuclose={handleClose} allfilename={ReferencesData} />

                        <MenuItem onClick={() => { handleDeleteFolder(numericId || 0) }} disableRipple>
                            Delete Folder
                        </MenuItem>
                        <MenuItem onClick={() => onDownloadClick(idFolder)} disableRipple>
                            Download Folder
                        </MenuItem>
                    </StyledMenu>

                </Box>
            </Box>

            <Divider sx={{ width: '100%', borderColor: theme => theme.palette.grey[300], borderWidth: '1px', marginTop: '20px' }} />

            <Box marginTop="10px" >
                <MaterialReactTable
                    columns={columns}
                    data={ReferencesData}
                    enableRowVirtualization
                    positionActionsColumn="last"
                    muiTablePaperProps={{
                        elevation: 0
                    }}
                    renderTopToolbarCustomActions={() => (
                        <Stack direction="row" spacing={2}>
                            <div ref={editRef}>
                                {isDialogOpen ?
                                    <TextField
                                        id="Folder Name"
                                        variant="outlined"
                                        label="Folder Name"
                                        value={folderNameEdit}
                                        onChange={(e) => setFolderNameEdit(e.target.value)}
                                        fullWidth />
                                    :
                                    <Typography variant="body1" color="textSecondary">
                                        {folderNames}
                                        <IconButton color="primary" onClick={createFolder} sx={{ fontSize: "12px", marginLeft: "10px" }}>
                                            <Pencil />
                                        </IconButton>
                                    </Typography>
                                }

                            </div>

                        </Stack>
                    )}
                    enableRowActions
                    renderRowActions={(row) => (
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "nowrap",
                                gap: "0.5",
                                marginLeft: "-5px",
                            }}
                        >
                            {(row?.row?.original as any)?.folderName !== "Templates" && <>
                                <Tooltip arrow placement="right" title="Edit">
                                    <IconButton
                                        sx={{ width: "35px", height: "35px" }}
                                        onClick={() => {
                                            handleEditFile(row?.row);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="right" title="Delete">
                                    <IconButton
                                        sx={{ width: "35px", height: "35px" }}
                                        onClick={() => {
                                            deleteDialogControls.open();
                                            setDeleteFiles(row?.row?.original);
                                        }}
                                    >
                                        <TrashIcon />
                                    </IconButton>
                                </Tooltip>
                            </>}
                            <Tooltip arrow placement="right" title="Download">
                                <IconButton
                                    sx={{ width: "35px", height: "35px" }}
                                    onClick={() => {
                                        onDownloadFileClick(row?.row?.original?.id);
                                    }}
                                >
                                    <ArrowdownIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
            </Box>
            <AddFilesRepoModal
                isOpen={addFileModalOpen}
                onClose={handleCloseFileModal}
                onCancel={handleCloseFileModal}
                editFile={EditFiles}
                success={successApi}
                FilesData="files"
            />
            <DeleteDialogIntensity
                isOpen={deleteDialogControls.isOpen}
                onCancel={deleteDialogControls.close}
                onDeleteClick={() => handleDeleteFiles(toDeleteFiles)}
                title="Delete documents?"
                filesrepo="Filesrepo"

            />
        </MarginWrapper>
    )
}

export default FoldersPage








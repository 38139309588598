/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import { COLORS, Loading, MultiSelectControlledOption } from "components";

import { ChartWrapper } from "../charts/chartLayoutWrapper";
// import MekkoChart from "../charts/mekkoChart";
import {
  CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_DATA,
  CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA,
  CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA,
  CONSUMPTIONS_BY_SITE_HIGHCHARTS_DATA,
  CONSUMPTIONS_MONTHLY_HIGHCHARTS_ELE_DATA,
  CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_ELE_DATA,
  UTILITY_NAMES,
} from "../consts";
import {
  findCommonElements,
  generateMonths,
  parseConsumptionDataMonthly,
  parseCountriesConsumptionData,
  parseFTConsumptionData,
  parseRegionsConsumptionData,
  parseSiteConsumptionData,
  parseYearConsumptionData,
} from "../utils";

type Props = {
  monthConsumptionYear: string;
  setMonthConsumptionYear: (data: string) => void;
  monthlyData: unknown;
  sitesData: unknown;
  yearlyData: unknown;
  regionsData: unknown;
  facilityTypesData: unknown;
  countriesResp: unknown;
  sitesOptions: MultiSelectControlledOption[];
  countryRegionsList: string[];
  facilityTypesList: string[];
  startMonth: string;
  endMonth: string;
  selectedYear: number;
};

export const ConsumptionElectricityComponent = ({
  monthlyData,
  sitesData,
  yearlyData,
  regionsData,
  facilityTypesData,
  countriesResp,
  sitesOptions,
  countryRegionsList,
  facilityTypesList,
  monthConsumptionYear,
  setMonthConsumptionYear,
  startMonth,
  endMonth,
  selectedYear
  
}: Props) => {
  // const [isConsumptionMonthFullscreen, setisConsumptionMonthFullscreen] =
  //   useState(false);
  // const [isConsumptionSiteFullscreen, setIsConsumptionSiteFullscreen] =
  //   useState(false);
  // const [isConsumptionYearFullscreen, setIsConsumptionYearFullscreen] =
  //   useState(false);
  // const [isConsumptionRegionFullscreen, setIsConsumptionRegionFullscreen] =
  //   useState(false);
  // const [isConsumptionFacilityType, setIsConsumptionFacilityType] =
  //   useState(false);
  // const [isConsumptionUSvsReactWorld, setisConsumptionUSvsReactWorld] =
  //   useState(false);


  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);


  const [
    monthlyConsumptionHighChartsData,
    setMonthlyConsumptionHighChartsData,
  ] = useState(CONSUMPTIONS_MONTHLY_HIGHCHARTS_ELE_DATA);

  const [siteConsumptionHighChartsData, setSiteConsumptionHighChartsData] =
    useState(CONSUMPTIONS_BY_SITE_HIGHCHARTS_DATA);

  const [yearConsumptionData, setYearConsumptionData] = useState(
    CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_ELE_DATA,
  );

  const [regionsConsumptionData, setRegionsConsumptionData] = useState(
    CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA,
  );

  const [ftConsumptionData, setFTConsumptionData] = useState(
    CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA,
  );

  const [countriesCSata, setCountriesCSData] = useState(
    CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_DATA,
  );

  useEffect(() => {
    if (monthlyData) {
      const parsedData = parseConsumptionDataMonthly(
        monthlyData,
        startMonth,
        endMonth,
        UTILITY_NAMES.ELECTRICITY,
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_MONTHLY_HIGHCHARTS_ELE_DATA),
      );
      const monthsHeaders = generateMonths(startMonth, endMonth, selectedYear);
      const xAxisData = monthsHeaders.map((monthValue) => {
        const [year, monthName] = monthValue.split(" ");
        const shortYear = year.slice(-2);
        return `${monthName} '${shortYear}`;
      });
      updatedChartsData.series = parsedData;
      updatedChartsData.xAxis.categories = xAxisData;
      updatedChartsData.yAxis.title.text = "kWh";
      setMonthlyConsumptionHighChartsData(updatedChartsData);
    }
    setIsLoading(false)
  }, [monthlyData, monthConsumptionYear]);

  useEffect(() => {
    if (sitesData) {
      const parsedData = parseSiteConsumptionData(
        sitesData,
        sitesOptions,
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_SITE_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = sitesOptions.map((e) => e.label);
      const indexArraymain: any[] = [];
      parsedData.filter((e: any) => {
        const indexArray: any[] = [];
        e.data.forEach((key: any, index: any) => {
          if (key.y === 0) {
            indexArray.push(index);
          }
        });
        return indexArraymain.push(indexArray);
      });

      const commonElements = findCommonElements(...indexArraymain);
      const modifiedData = parsedData.map((series: any) => ({
        name: `FY${series.name}`, // Append 'FY' before the year
        data: series.data,
      }));
      
      updatedChartsData.series = modifiedData;
      const sort = commonElements.sort((a, b) => b - a);
      sort.forEach((element: Number) => {
        updatedChartsData.xAxis.categories.splice(Number(element), 1);
        parsedData.filter((e: any) => e.data.splice(Number(element), 1));
      });
      setSiteConsumptionHighChartsData(updatedChartsData);
    }
    setIsLoading1(false)
  }, [sitesData]);

  useEffect(() => {
    if (yearlyData) {
      const parsedData = parseYearConsumptionData(
        yearlyData,
        "consumption",
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_YEARLY_HIGHCHARTS_DATA_ELE_DATA),
      );
      updatedChartsData.xAxis.categories = Object.keys(yearlyData)
        .sort()
        .map((e) => `FY${e}`);
      updatedChartsData.series = parsedData;
      setYearConsumptionData(updatedChartsData);
    }
    setIsLoading2(false)
  }, [yearlyData]);

  useEffect(() => {
    if (regionsData) {
      const parsedData = parseRegionsConsumptionData(
        regionsData,
        countryRegionsList,
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_REGION_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = countryRegionsList;
      updatedChartsData.series = parsedData;
      setRegionsConsumptionData(updatedChartsData);
    }
  }, [regionsData]);

  useEffect(() => {
    if (facilityTypesData) {
      const parsedData = parseFTConsumptionData(
        facilityTypesData,
        facilityTypesList,
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_FACILITY_TYPES_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = facilityTypesList;
      updatedChartsData.series = parsedData;
      setFTConsumptionData(updatedChartsData);
    }
  }, [facilityTypesData]);

  useEffect(() => {
    if (countriesResp) {
      const parsedData = parseCountriesConsumptionData(
        countriesResp,
        ["US", "Rest of world"],
        UTILITY_NAMES.ELECTRICITY,
        startMonth,
        endMonth
      );
      const updatedChartsData = JSON.parse(
        JSON.stringify(CONSUMPTIONS_BY_COUNTRIES_HIGHCHARTS_DATA),
      );
      updatedChartsData.xAxis.categories = ["US", "Rest of world"];
      updatedChartsData.series = parsedData;
      setCountriesCSData(updatedChartsData);
    }
  }, [countriesResp]);
 
  const datacheck = (datares: any) => {
    const hasNonZeroValue = datares?.series?.some((item: any) => {
      // Check if item exists and is an array
      if (Array.isArray(item?.data)) {
        // Check if any y value is not equal to 0
        return item.data.some((value: number | null) => value !== null && value !== 0);
      }
      return false; // If item is not an array, consider it as false
    });
  
    return !!hasNonZeroValue; // Return false if all values are either null or 0
  };

  const monthlyConsumption = datacheck(monthlyConsumptionHighChartsData);
  const hasFalseValue = datacheck(siteConsumptionHighChartsData);
  const hasFalseyearConsumption =  datacheck(yearConsumptionData)
  const consumptionbyRegion = datacheck(regionsConsumptionData)
  const ftConsumption = datacheck(ftConsumptionData)
  const countries = datacheck(countriesCSata)

  return (
    <>
      <ChartWrapper
        label={
          <Typography
            sx={{
              color: monthlyConsumption
                ? COLORS.lightgray
                : "#00000042",
                fontSize:"16px",
                fontFamily:"Manrope",
                fontWeight:"600"
            }}
          >
            Consumption Month on Month
          </Typography>
        }
        selectedYear={monthConsumptionYear}
        setSelectedYear={setMonthConsumptionYear}
        id="ConsumptionMonth"
      >
          {isLoading ? (<Loading/>) :monthlyConsumption?
        <HighchartsReact
          highcharts={Highcharts}
          options={monthlyConsumptionHighChartsData}
        />
        : <Box sx={{backgroundColor:"#f8f8f8" , height:"300px"}}> <Typography sx={{textAlign:"center" , alignItems:"center" ,pt:"100px" , fontSize:"25px"}}>No data available</Typography></Box>
      }
      </ChartWrapper>

      <ChartWrapper
        label={
          <Typography
            sx={{
              color: hasFalseValue
                ? COLORS.lightgray
                : "#00000042",
                fontSize:"16px",
                fontFamily:"Manrope",
                fontWeight:"600"
            }}
          >
          Consumption by Site
          </Typography>
        }
        id="ConsumptionbySite"
        //  fullScreenChange={setIsConsumptionSiteFullscreen}
      >
          {isLoading1 ? (<Loading/>) :hasFalseValue?
        <HighchartsReact
          highcharts={Highcharts}
          options={siteConsumptionHighChartsData}
          // containerProps={{
          //   className: isConsumptionSiteFullscreen ? "fullscreen-chart" : "chart-container",
          // }}
        />
        : <Box sx={{backgroundColor:"#f8f8f8" , height:"300px"}}> <Typography sx={{textAlign:"center" , alignItems:"center" ,pt:"100px" , fontSize:"25px"}}>No data available</Typography></Box>
      }
      </ChartWrapper>

      <Stack direction="row" alignItems="center" spacing={2}>
        {/* <ChartWrapper
          label="Consumption by Facility type"
          yearOptions={utYearsList}
          selectedYear={utYear}
          setSelectedYear={setUTYear}
          renderYearDropdown
        >
          <MekkoChart data={scopeEmissionsData} />
        </ChartWrapper> */}
        {/* <ChartWrapper label="Consumption Year on Year">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={yearConsumptionData}
                    />
                </ChartWrapper> */}
      </Stack>

      <ChartWrapper
        // label="Consumption Year on Year"
        label={
          <Typography
            sx={{
              color: hasFalseyearConsumption
                ? COLORS.lightgray
                : "#00000042",
                fontSize:"16px",
                fontFamily:"Manrope",
                fontWeight:"600"
            }}
          >
          Consumption Year on Year
          </Typography>
        }
        id="ConsumptionYear"
        //  fullScreenChange={setIsConsumptionYearFullscreen}
      >
         {isLoading2 ? (<Loading/>) :hasFalseyearConsumption?
        <HighchartsReact
          highcharts={Highcharts}
          options={yearConsumptionData}
          // containerProps={{
          //   className: isConsumptionYearFullscreen ? "fullscreen-chart" : "chart-container",
          // }}
        />
        : <Box sx={{backgroundColor:"#f8f8f8" , height:"300px"}}> <Typography sx={{textAlign:"center" , alignItems:"center" ,pt:"100px" , fontSize:"25px"}}>No data available</Typography></Box>
      }
      </ChartWrapper>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ChartWrapper
          styles={{ width: "50%" }}
          // label="Consumption by Region"
          label={
            <Typography
              sx={{
                color: consumptionbyRegion
                  ? COLORS.lightgray
                  : "#00000042",
                  fontSize:"16px",
                  fontFamily:"Manrope",
                  fontWeight:"600"
              }}
            >
            Consumption by Region
            </Typography>
          }
          id="ConsumptionbyRegion"
          //  fullScreenChange={setIsConsumptionRegionFullscreen}
        >
            {isLoading1 ? (<Loading/>) :consumptionbyRegion?
          <HighchartsReact
            highcharts={Highcharts}
            options={regionsConsumptionData}
            // containerProps={{
            //   className: isConsumptionRegionFullscreen ? "fullscreen-chart" : "chart-container",
            // }}
          />
          : <Box sx={{backgroundColor:"#f8f8f8" , height:"300px"}}> <Typography sx={{textAlign:"center" , alignItems:"center" ,pt:"100px" , fontSize:"25px"}}>No data available</Typography></Box>
        }
        </ChartWrapper>

        <ChartWrapper
          styles={{ width: "50%" }}
          // label="Consumption by Facility type"
          label={
            <Typography
              sx={{
                color: ftConsumption
                  ? COLORS.lightgray
                  : "#00000042",
                  fontSize:"16px",
                  fontFamily:"Manrope",
                  fontWeight:"600"
              }}
            >
           Consumption by Facility type
            </Typography>
          }
          id="ConsumptionbyFacility"
          //  fullScreenChange={setIsConsumptionFacilityType}
        >
            {ftConsumption?
          <HighchartsReact
            highcharts={Highcharts}
            options={ftConsumptionData}
            // containerProps={{
            //   className: isConsumptionFacilityType ? "fullscreen-chart" : "chart-container",
            // }}
          />
          : <Box sx={{backgroundColor:"#f8f8f8" , height:"300px"}}> <Typography sx={{textAlign:"center" , alignItems:"center" ,pt:"100px" , fontSize:"25px"}}>No data available</Typography></Box>
        }
        </ChartWrapper>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ChartWrapper
          styles={{ width: "50%" }}
          // label="Consumption by US vs Rest of world"
          label={
            <Typography
              sx={{
                color: countries
                  ? COLORS.lightgray
                  : "#00000042",
                  fontSize:"16px",
                  fontFamily:"Manrope",
                  fontWeight:"600"
              }}
            >
          Consumption by US vs Rest of world
            </Typography>
          }
          id="ConsumptionbyUS"
          // fullScreenChange={setisConsumptionUSvsReactWorld}
        >
             {countries?
          <HighchartsReact
            highcharts={Highcharts}
            options={countriesCSata}
            // containerProps={{
            //   className: isConsumptionUSvsReactWorld ? "fullscreen-chart" : "chart-container",
            // }}
          />
          : <Box sx={{backgroundColor:"#f8f8f8" , height:"300px"}}> <Typography sx={{textAlign:"center" , alignItems:"center" ,pt:"100px" , fontSize:"25px"}}>No data available</Typography></Box>
        }
        </ChartWrapper>

        {/* <ChartWrapper
          styles={{ width: "50%" }}
          label="Consumption by Utility Type"
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={utCSDataTwoFields}
          />
        </ChartWrapper> */}
      </Box>
    </>
  );
};

/* eslint-disable */
import { Search, Send } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import MaterialReactTable from "material-react-table";
import React, { useState } from "react";
import * as XLSX from "xlsx";

import { SiteDTO, deleteSite, exportSites, uploadSites } from "api";
import { getRecDocumentDetails } from "api/rec";

import { EditIcon, PlusIcon, SitemultiRegion, TrashIcon } from "assets";

import {
  DeleteDialog,
  EMPTY_CELL_VALUE,
  H5Bold,
  Loading,
  MarginWrapper,
  TextField,
  useDialogControls,
} from "components";
import { RecUploadButton } from "components/buttons/rec-upload-button";

import { useGetAllowedValues, useNotify, useSites } from "hooks";

import { QUERY_KEYS } from "consts";

import { USER_ROLES, useNewBreadcrumb, useUserRole } from "recoils";

import { SiteAccordion } from "./components";
import { AddSiteModal } from "./components/site-editor-form/components/AddSiteModal";
import { SiteColumns } from "./components/site-editor-form/components/SiteColumns";

import { getAllowedValues } from "../sites/components";
import { SITES_PAGE } from "../sites/consts";
import { convertBlankStringsToNull } from "utils";

export const SitesPageV2 = () => {
  useNewBreadcrumb(SITES_PAGE.TITLE);
  const { data: sites, isLoading } = useSites();
  const userRole = useUserRole();
  const [addSiteModalOpen, setAddSiteModalOpen] = useState(false);
  const [EditSiteOpen, setEditSiteOpen] = useState(false);
  const [toDeactivateSite, setDeactivateSite] = useState<any | undefined>();
  const [EditSite, setEditSite] = useState<any | undefined>();

  const activatedSites = sites?.filter((t) => t.enabled === true);
  const deActivatedSites = sites?.filter((t) => t.enabled === false);

  const [searchValue, setSearchValue] = useState("");
  const [filterSites, setFilterSites] = useState<SiteDTO[]>(
    activatedSites || [],
  );

  const notify = useNotify();

  const { data: allowedValues } = useGetAllowedValues();
  const { mutateAsync: disableSiteRequest } = useMutation(deleteSite);

  const data = exportSites();


  const deleteDialogControls = useDialogControls();

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
    handleSearch(value);
  };

  const handleSearch = (value: string) => {
    if (value === "") {
      setFilterSites(activatedSites || []);
    } else {
      const filteredActivatedRecords = activatedSites?.filter(
        (record) =>
          (record.name &&
            record.name.toLowerCase().includes(value.toLowerCase())) ||
          (record.type &&
            record.type.toLowerCase().includes(value.toLowerCase())) ||
          (record.city &&
            record.city.toLowerCase().includes(value.toLowerCase())) ||
          (record.country &&
            record.country.toLowerCase().includes(value.toLowerCase())),
      );
      setFilterSites(filteredActivatedRecords || []);
    }
  };

  const siteDetailsColumns = [
    {
      accessorKey: "name",
      header: "Site Name",
      size: 100,
      // eslint-disable-next-line
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;

        if (!value) {
          return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
        }
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                alignItems: "center",
                width: "160px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Type of site",
      size: 100,
    },
    {
      accessorKey: "city",
      header: "City",
      size: 90,
    },
    {
      accessorKey: "country",
      header: "Country",
      size: 100,
    },
    {
      accessorKey: "countryRegion",
      header: "Region",
      size: 100,
    },
    {
      accessorKey: "region",
      header: "Electricity Region Code",
      size: 160,
      // eslint-disable-next-line
      Cell: ({ row }: any) => {
        const { multipleRegion: regionmultiple, region } = row.original;

        let message = "";
        if (region === null) {
          message = regionmultiple
            ? "Multiple region codes found"
            : "Missing region code";
        }

        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {region === null ? (
              <span style={{ color: "#D86262" }}>
                <SitemultiRegion
                  style={{
                    marginRight: "2px",
                    paddingTop: regionmultiple ? "0px" : "4px",
                  }}
                />
                {message}{" "}
              </span>
            ) : (
              <span>{region} </span>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "address",
      header: "Street Address",
      size: 100,
      // eslint-disable-next-line
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;

        if (!value) {
          return <Box sx={{ paddingLeft: "15px" }}>{EMPTY_CELL_VALUE}</Box>;
        }
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                alignItems: "center",
                width: "160px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "zipCode",
      header: "ZipCode",
      size: 90,
    },
  ];

  const handleEditSite = (rowData: any) => {
    setEditSiteOpen(true);
    setEditSite(rowData);
  };

  const handleCloseSiteModal = () => {
    if (addSiteModalOpen) {
      setAddSiteModalOpen(false);
    }
    if (EditSiteOpen) {
      setEditSiteOpen(false);
    }
    setEditSite(undefined);
  };

  const handleDeActivateSite = (siteId: number) => {
    if (siteId) {
      disableSiteRequest(siteId, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(`Some error has happen while de-activating the  site!`);
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Site successfully De-Activated!`);
          window.location.reload();
        },
      });
    }
  };

  const activatedRecords =
    (activatedSites &&
      JSON.parse(
        JSON.stringify(searchValue.length > 0 ? filterSites : activatedSites),
      )) ||
    [];

  const deActivatedRecords =
    (deActivatedSites && JSON.parse(JSON.stringify(deActivatedSites))) || [];

  const exportToExcel = async () => {
    const sites = await exportSites();
    const siteInfo = sites.map((site: any) => ({
      "Site Name": site.name,
      "Type of site": site.type,
      City: site.city,
      Country: site.country,
      Region: site.countryRegion,
      "Electricity Region Code": site.region,
      Address: site.address,
      ZipCode: site.zipCode,
    }));

    const utilityProviderInfo = sites.flatMap((site) =>
      site.collectors.map((collector: any) => ({
        "Site Name": site.name,
        "Utility Type": collector.utilityTypeName,
        utilitySubType: collector.utilitySubType,
        "Account Number": collector.accountNumber,
        "Meter Number": collector.meterNumber,
        "Utility Provider": collector.providerName,
        Frequency: collector.frequency,
        "Consumption Unit": collector.consumptionUnit,
        Currency: collector.currency,
      })),
    );

    const parameterBasedEstimatesInfo = sites.flatMap((site) =>
      site.estimates.map((estimate: any) => ({
        "Customer Site Id": site.customerSiteId,
        "Estimate Name": estimate.estimateName,
        "Estimate Type": estimate.estimateType,
        "Usage Per Day": estimate.estimateUsage,
        "Estimation Value": estimate.estimateHeadCount,
        "Unit": estimate.estimateUsageUnit,
        "Start Date": estimate.estimateStartDate,
        "End Date": estimate.estimateEndDate,
        "Site Name": estimate.siteName,
        "Utility Type": estimate.estimateName,
        "Notes": estimate.notes,
        "Scope": estimate.scope,
      })),
    );

    const dataProvidersInfo = sites.flatMap((site) =>
      site.users.map((user: any) => ({
        "Site Name": site.name,
        "First Name": user.firstName,
        "Last Name": user.lastName,
        Email: user.email,
      })),
    );

    const renewableEnergyCertificatesInfo = [];

    for (const site of sites) {
      for (const collector of site.collectors) {
        if (collector.recs) {
          const recId = collector.recsDetail.recId;
          const recResponse = await getRecDocumentDetails(recId); // Make API call to fetch REC details
          const recData = recResponse.data; // Access the data property

          const collectorRecsString = recData.collectorRecsDtoList
            .map(
              (rec: any) =>
                `${rec.siteName}- ${rec.meterNumber || "N/A"}-${
                  rec.accountNumber || "N/A"
                }`,
            )
            .join("\n");

          renewableEnergyCertificatesInfo.push({
            "Utility Provider": recData.utilityProvider,
            "Utility Type": recData.utilityTypeName,
            "Start Date": recData.startDate,
            "End Date": recData.endDate,
            Scope: collector.scope,
            Collector: collectorRecsString,
          });
        }
      }
    }

    const wb = XLSX.utils.book_new();

    const wsSiteInfo = XLSX.utils.json_to_sheet(siteInfo);
    const wsUtilityProviderInfo = XLSX.utils.json_to_sheet(utilityProviderInfo);
    const wsParameterBasedEstimatesInfo = XLSX.utils.json_to_sheet(
      parameterBasedEstimatesInfo,
    );
    const wsDataProvidersInfo = XLSX.utils.json_to_sheet(dataProvidersInfo);
    const wsRenewableEnergyInfo = XLSX.utils.json_to_sheet(
      renewableEnergyCertificatesInfo,
    );

    XLSX.utils.book_append_sheet(wb, wsSiteInfo, "Site Info");
    XLSX.utils.book_append_sheet(
      wb,
      wsUtilityProviderInfo,
      "Utility Provider Info",
    );
    XLSX.utils.book_append_sheet(
      wb,
      wsParameterBasedEstimatesInfo,
      "Parameter Estimates Setup",
    );
    XLSX.utils.book_append_sheet(
      wb,
      wsDataProvidersInfo,
      "Data Providers Info",
    );
    XLSX.utils.book_append_sheet(wb, wsRenewableEnergyInfo, "RECS Info");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    const s2ab = (s: any) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "Site_Management_Data.xlsx",
    );
  };

  return (
    <MarginWrapper>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 5 }}>
        <RecUploadButton
          label="Upload Data"
          uploadApiCall={uploadSites}
          queryKeyToInvalidate={QUERY_KEYS.SITES}
          availableToRoles={[USER_ROLES.ADMIN]}
          extraReqParams={[["configuration", "true"]]}
        />
        <Button
          sx={{ marginTop: "10px" }}
          variant="contained"
          onClick={() => setAddSiteModalOpen(true)}
          startIcon={<PlusIcon />}
          disabled={userRole.isAuditor || userRole.isDataProvider}
        >
          Add site
        </Button>
        <Button
          sx={{ marginTop: "10px" }}
          variant="contained"
          onClick={exportToExcel}
          endIcon={<Send />}
        >
          Export to Excel
        </Button>
      </Box>

      <Box
        display="flex"
        gap="2"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
        sx={{ marginLeft: "auto", width: "fit-content" }}
      >
        <IconButton>
          <Search />
        </IconButton>
        <TextField
          sx={{
            "& .MuiInput-underline:before": {
              borderBottomWidth: "1.8px",
            },
          }}
          placeholder="Search sites..."
          variant="standard"
          value={searchValue}
          onChange={handleSearchChange}
        />
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        (activatedSites || []) && (
          <MaterialReactTable
            state={{ isLoading }}
            columns={SiteColumns}
            enableGlobalFilter={false}
            data={activatedRecords.concat(deActivatedRecords)}
            enablePagination
            muiTableBodyCellProps={{
              sx: {
                backgroundColor: 'white',
              },
            }}
            positionActionsColumn="last"
            defaultColumn={{
              minSize: 20,
              maxSize: 9001,
              size: 320,
            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [100, 200, 500, 1000],
              showFirstButton: true,
              showLastButton: true,
            }}
            initialState={{ pagination: { pageSize: 100, pageIndex: 0 } }}
            renderDetailPanel={({ row }) => (
              <>
                <H5Bold mt={1} mb={1}>
                  Site Details
                </H5Bold>
                <MaterialReactTable
                  columns={siteDetailsColumns}
                  data={[row.original]}
                  enablePagination={false}
                  enableDensityToggle={false}
                  initialState={{
                    density: "compact",
                  }}
                  enableRowActions
                  enableFullScreenToggle={false}
                  enableColumnFilters={false}
                  enableHiding={false}
                  renderRowActions={() => (
                    <>
                      {row.original.enabled ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            gap: "0.5",
                            marginLeft: "-5px",
                          }}
                        >
                          <Tooltip arrow placement="right" title="Edit">
                            <IconButton
                              sx={{ width: "35px", height: "35px" }}
                              onClick={() => {
                                handleEditSite(convertBlankStringsToNull(row.original));
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow placement="right" title="De-Activate">
                            <IconButton
                              sx={{ width: "35px", height: "35px" }}
                              onClick={() => {
                                deleteDialogControls.open();
                                setDeactivateSite(row.original?.id);
                              }}
                            >
                              <TrashIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : (
                        <span style={{ color: "red" }}>De-activated</span>
                      )}
                    </>
                  )}
                />

                {row.original.enabled && <SiteAccordion data={row.original} />}
              </>
            )}
          />
        )
      )}

      <AddSiteModal
        isOpen={addSiteModalOpen || EditSiteOpen}
        onClose={handleCloseSiteModal}
        allowedValues={getAllowedValues(allowedValues)}
        editSite={EditSite}
      />
      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={() => handleDeActivateSite(toDeactivateSite)}
        target="Site"
        title="De-activate Site?"
      />
    </MarginWrapper>
  );
};

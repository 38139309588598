import {
  getResData,
  httpClient,
} from "utils";

import { REC_API_PATHS } from "./consts";

export const createRecDocument = ({
  fileName,
  recId
}: {
  fileName: string[];
  recId: number;
}) =>
  httpClient
    .post(
      REC_API_PATHS.RECsDOCUMENT(),
      { fileName, recId },
    )
    .then(getResData);

export const RecDocumentUploaded = (payload: any) =>
  httpClient.post(REC_API_PATHS.RECsDOCUMENT_UPLOADED, payload);

export const getRecDocumentDetails = (recId: any) =>
  httpClient.get(REC_API_PATHS.RECsDOCUMENT_DETAILS(recId));

export const editRecDocumentDetails = ({
  recId,
  payload,
}: {
  recId: any;
  payload: any;
}) =>
  httpClient.put(REC_API_PATHS.RECsDOCUMENT_DETAILS(recId), payload);

export const activateRecDetails = (payload: any) =>
  httpClient.put(`recdocument/activate`, payload);

export const deActivateRecDetails = (payload: any) =>
  httpClient.put(`recdocument/de-activate`, payload);

  
export const deleteRecDocument = (recId: any) =>
  httpClient.delete(REC_API_PATHS.RECsDOCUMENT_DETAILS(recId));






import MaterialReactTable from "material-react-table";
import React from "react";
import { Loading } from "components";
import { useEmissionFactors } from "hooks";
import { EMISSION_FACTORS_COLUMNS } from "./components";

export const EmissionFactors = ({ ReferencesData }: { ReferencesData: any }) => {
    const { isLoading } = useEmissionFactors();
    const columns = EMISSION_FACTORS_COLUMNS;
    return (
            <div>
            {isLoading ? (
                <Loading />
            ) : (

                <MaterialReactTable
                    columns={columns}
                    muiTableBodyCellProps={{
                        sx: {
                          backgroundColor: 'white',
                        },
                      }}
                    data={ReferencesData}
                    enableColumnResizing
                    enableDensityToggle={false}
                    initialState={{ density: "compact" }}
                    enablePagination={false}
                    enableRowVirtualization
                />
            )}
            </div>
    );
};

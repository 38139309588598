import React, {useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ConfigParameterDTO } from "api";

import { Layout } from "components";

import { useGetConfigParameter } from "hooks/api";

import { DashboardPage } from "./dashboards";
import { DOCUMENTS_ROUTER, DOCUMENT_UPLOAD_PAGE } from "./documents";
import { DocumentsRouter } from "./documents/router";
import {
  DocumentUploadPageScope3,
  SCOPE_DOCUMENT_UPLOAD_PAGE_SCOPE3,
} from "./documents/scope-upload-scope3";
import { EMISSION_FACTORS_PAGEINDEX } from "./emission-factors";
import {
  EMISSION_FACTORS_PAGE_SCOPE3,
  EmissionFactorsPageScope3,
} from "./emission-factors-scope3";
import { SETTINGS_ROUTER } from "./settings";
import { LEGAL_PAGE_SETTINGS, LegalPage } from "./settings/legal";
import { USER_SECURITY_PAGE, UserSecurityPage } from "./settings/security";
import { SettingsRouterV2 } from "./settingsv2";
import { INTENSITY_PAGE, SITES_PAGE } from "./sites";
import { SITES_EMISSIONS_PAGE, SitesEmissionsPage } from "./sites-emissions";
import { ResourcesRouter } from "./emission-factors/router";
import {
  SITES_EMISSIONS_PAGE_SCOPE3,
  SitesEmissionsPageScope3,
} from "./sites-emissions-scope3";
import { SitesPageV2 } from "./sitesv2";
import { RecUpload } from "./sitesv2/rec-upload";
import { IntensityPage } from "./sites/intensity";
import { AUDIT_LOGS_PAGE, AuditLogsPage } from "./audit-logs";

const initialScope = { activeScope: [""] };
export const Scope3Context = React.createContext(initialScope);
const getConfigParameterObject = (data: ConfigParameterDTO[]) =>
  data.reduce<Record<string, Record<string, string | number>>>(
    (acc, parameter) => {
      acc[parameter.name] = {
        id: parameter.id,
        configValue: parameter.configValue,
      };

      return acc;
    },
    {},
  );

export const AuthenticatedRouter = () => {
  const [activeScope, setActiveScope] = useState(initialScope);
  const { data, isLoading: isConfigDataLoading } = useGetConfigParameter();
  useEffect(() => {
    if (!isConfigDataLoading && data) {
      const scopes = ["scope1", "scope2", "scope3"];
      const configParameter = getConfigParameterObject(data);
      const scopeList: string[] = [];
      scopes.map((item) =>
        configParameter[item].configValue === "true"
          ? scopeList.push(item)
          : null,
      );
      setActiveScope({ activeScope: scopeList });
    }
  }, [data, isConfigDataLoading]);

  return (

      <Scope3Context.Provider value={activeScope}>
        <Layout>
          <Routes>
            <Route path={DOCUMENTS_ROUTER.PATH} element={<DocumentsRouter />} />
            <Route
              path={EMISSION_FACTORS_PAGEINDEX.PATH}
              element={<ResourcesRouter />}
            />
            <Route
              path={SITES_EMISSIONS_PAGE.ABSOLUTE_PATH}
              element={<SitesEmissionsPage />}
            />
            <Route
              path={SITES_EMISSIONS_PAGE_SCOPE3.ABSOLUTE_PATH}
              element={<SitesEmissionsPageScope3 />}
            />
            <Route path={SITES_PAGE.ABSOLUTE_PATH} element={<SitesPageV2 />} />
            <Route path={AUDIT_LOGS_PAGE.ABSOLUTE_PATH} element={<AuditLogsPage />} />
            <Route path='rec/:recId' element={<RecUpload />} />
            <Route path='rec/:recId' element={<RecUpload />} />
            <Route path={INTENSITY_PAGE.ABSOLUTE_PATH} element={<IntensityPage />} />
            <Route
              path={EMISSION_FACTORS_PAGE_SCOPE3.ABSOLUTE_PATH}
              element={<EmissionFactorsPageScope3 />}
            />
            <Route path={SETTINGS_ROUTER.PATH} element={<SettingsRouterV2 />} />
            <Route
              path={USER_SECURITY_PAGE.PATH}
              element={<UserSecurityPage />}
            />
            <Route
              path={LEGAL_PAGE_SETTINGS.ABSOLUTE_PATH}
              element={<LegalPage />}
            />
            {/* <Route
            path={SETTINGS_ROUTER_V2.PATH}
            element={<SettingsRouterV2 />}
          /> */}
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route
              path={SCOPE_DOCUMENT_UPLOAD_PAGE_SCOPE3.ABSOLUTE_PATH}
              element={<DocumentUploadPageScope3 />}
            />
            <Route
              path="*"
              element={<Navigate to={DOCUMENT_UPLOAD_PAGE.ABSOLUTE_PATH} />}
            />
            <Route path="*" element={<Navigate to="/rest-learning" />} />
          </Routes>
        </Layout>
      </Scope3Context.Provider>
  );
};

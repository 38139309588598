/* eslint-disable */
import SendIcon from "@mui/icons-material/Send";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import MaterialReactTable, {
  MRT_ColumnFiltersState,
  type MRT_RowSelectionState,
} from "material-react-table";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";

import {
  FILTERS_AND_ACTIONS_WRAPPER,
  FLEX_ALIGN_CENTER_GAP_2,
  Loading,
  MarginWrapper,
  NoResultFound,
  useEmissionPagination,
} from "components";
import { useTableExpandedRows } from "components/react-table";

import { useScopePendingEmissionFactors } from "hooks";

import { LOCAL_STORAGE_KEYS } from "consts";

import { useNewBreadcrumb } from "recoils";

import { EmissionActions, EmissionApproveActions } from "./components";
import emissionFactorColumn from "./components/table/columns";
import emissionFactorMatchedColumn from "./components/table/columnsMatched";
import { EMISSION_FACTORS_PAGE_SCOPE3 } from "./consts";

export const EmissionFactorsPageScope3 = () => {
  const [value, setValue] = React.useState("1");

  const [arrSupplierName, setSupplierNameFilter] = React.useState<any[]>([]);
  const [arrItemNumber, setItemNumberFilter] = React.useState<any[]>([]);
  const [arrUnit, setUnitFilter] = React.useState<any[]>([]);

  const [arrMatchedSupplierName, setMatchedSupplierNameFilter] = React.useState<
    any[]
  >([]);
  const [arrMatchedItemNumber, setMatchedItemNumberFilter] = React.useState<
    any[]
  >([]);
  const [arrMatchedGeography, setMatchedGeographyFilter] = React.useState<
    any[]
  >([]);

  const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>(
    {},
  );

  const { EMISSION_FACTORS_COLUMNS } = emissionFactorColumn(
    arrSupplierName,
    arrItemNumber,
    arrUnit,
  );
  const { EMISSION_FACTORS_MATCHED_COLUMNS } = emissionFactorMatchedColumn(
    arrMatchedSupplierName,
    arrMatchedItemNumber,
    arrMatchedGeography,
  );

  const status: any = {
    "1": "DECLARED",
    "2": "MATCHED",
    "3": "APPROVED",
  };
  window.sessionStorage.clear();
  const { setInitialExpanded, updateQueryParamsWithInitialExpanded } =
    useTableExpandedRows();
  useNewBreadcrumb(EMISSION_FACTORS_PAGE_SCOPE3.TITLE);

  const { setPageNumber } = useEmissionPagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.DOCUMENT_UPLOAD_PAGE_SIZE,
    updateExternalStates: setInitialExpanded,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialExpanded,
  });

  const { data, isLoading } = useScopePendingEmissionFactors({
    sortColumn: "START_DATE",
    direction: "DESC",
    status: status[value],
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setPageNumber(0);
  };

  const handleRowSelection = () => {
    setRowSelection({});
  };

  const tableRows = data?.supplies || [];

  const columns = EMISSION_FACTORS_COLUMNS;
  const columnsMatched = EMISSION_FACTORS_MATCHED_COLUMNS;

  const CsvPendingData = tableRows.map((item: any) => ({
    "Supplier Name": item.supplier_name,
    Category: item.category,
    "Item Number": item.supplier_item_number,
    "Product Name": item.scope3EcoInvent.product_name,
    "Product Description": item.description_1,
    Quantity: item.quantity,
    Units: item.conversion_unit,
    Status: item.status,
  }));

  const CsvMatchedData = tableRows.map((item: any) => ({
    "Supplier Name": item.supplier_name,
    Category: item.category,
    "Item Number": item.supplier_item_number,
    "Product Description": item.description_1,
    "Product Description 2": item.description_2,
    "Product Description 3": item.description_3,
    "Ecoinvent Product Name": item.scope3EcoInvent.product_name,
    "Ecoinvent Activity name":
      item.scope3EcoInvent.emission_factor_activity_name,
    Geography: item.scope3EcoInvent.emission_factor_geography,
    "Product Information":
      item.scope3EcoInvent.emission_factor_product_information,
    Units: item.conversion_unit,
  }));

  const csvFileName = (dataValue: string) => {
    // switch (valaue)
    if (dataValue === "1") {
      return "Emission-Factor-Pending.csv";
    }
    if (dataValue === "2") {
      return "Emission-Factor-Matched.csv";
    }
    if (dataValue === "3") {
      return "Emission-Factor-Approved.csv";
    }
    return "";
  };

  // DropDown Filter For Pending Tab
  useEffect(() => {
    const supplierName: any[] = [];
    const itemNumber: any[] = [];
    const unit: any[] = [];

    tableRows.map((i: any) => supplierName.push(i.supplier_name));
    const supplierNameFilteredArray = supplierName.filter(
      (item: any, pos: any) => supplierName.indexOf(item) === pos,
    );
    setSupplierNameFilter(supplierNameFilteredArray);

    // ItemNumber
    tableRows.map((i: any) => itemNumber.push(i.supplier_item_number));
    const itemNumberFilteredArray = itemNumber.filter(
      (item: any, pos: any) => itemNumber.indexOf(item) === pos,
    );
    setItemNumberFilter(itemNumberFilteredArray);

    // Unit
    tableRows.map((i: any) => unit.push(i.units_of_measure));
    const unitFilteredArray = unit.filter(
      (item: any, pos: any) => unit.indexOf(item) === pos,
    );
    setUnitFilter(unitFilteredArray);

    // !DropDown Filter For Matched & Approved Tab
    const matchedSupplierName: any[] = [];
    const matchedItemNumber: any[] = [];
    const matchedGeography: any = [];
    const matchedGeographyScope3EcoInvent: any = [];

    // matchedSupplierName
    tableRows.map((i: any) => matchedSupplierName.push(i.supplier_name));
    const matchedSupplierNameFilteredArray = matchedSupplierName.filter(
      (item: any, pos: any) => matchedSupplierName.indexOf(item) === pos,
    );
    setMatchedSupplierNameFilter(matchedSupplierNameFilteredArray);

    // matchedItemNumber
    tableRows.map((i: any) => matchedItemNumber.push(i.supplier_item_number));
    const matchedItemNumberFilteredArray = matchedItemNumber.filter(
      (item: any, pos: any) => matchedItemNumber.indexOf(item) === pos,
    );
    setMatchedItemNumberFilter(matchedItemNumberFilteredArray);

    // matchedGeography
    tableRows.map((i: any) => matchedGeography.push(i.scope3EcoInvent));
    matchedGeography.length > 0 &&
      matchedGeography.map((i: any) =>
        matchedGeographyScope3EcoInvent.push(i.emission_factor_geography),
      );
    const matchedGeographyFilteredArray =
      matchedGeographyScope3EcoInvent.length > 0 &&
      matchedGeographyScope3EcoInvent.filter(
        (item: any, pos: any) =>
          matchedGeographyScope3EcoInvent.indexOf(item) === pos,
      );
    setMatchedGeographyFilter(matchedGeographyFilteredArray);
    // eslint-disable-next-line
  }, [tableRows]);

  return (
    <MarginWrapper>
      <TabContext value={value}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {value === "1" && (
            <EmissionActions
              rowSelection={rowSelection}
              setrowSelection={handleRowSelection}
            />
          )}
          {value === "2" && (
            <EmissionApproveActions
              rowSelection={rowSelection}
              setrowSelection={handleRowSelection}
            />
          )}
        </Box>
        <Box sx={FILTERS_AND_ACTIONS_WRAPPER}>
          <Box
            sx={{
              width: "70%",
              borderBottom: 1,
              borderColor: "divider",
              ...FLEX_ALIGN_CENTER_GAP_2,
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Pending" value="1" />
              <Tab label="Matched" value="2" />
              <Tab label="Approved" value="3" />
            </TabList>
          </Box>
          <Box>
            <Box sx={FLEX_ALIGN_CENTER_GAP_2}>
              <CSVLink
                data={value === "1" ? CsvPendingData : CsvMatchedData}
                filename={csvFileName(value)}
              >
                <Button variant="contained" endIcon={<SendIcon />}>
                  Export CSV
                </Button>
              </CSVLink>
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabPanel value="1">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <MaterialReactTable
                  columns={columns}
                  muiTableBodyCellProps={{
                    sx: {
                      backgroundColor: 'white',
                    },
                  }}
                  data={tableRows}
                  enableRowSelection
                  enablePagination={false}
                  getRowId={(row) => row.id}
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  enableRowVirtualization
                />

                {!tableRows.length ? (
                  <Box mt={3}>
                    <NoResultFound />
                  </Box>
                ) : null}
              </>
            )}
          </TabPanel>
          <TabPanel value="2">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <MaterialReactTable
                  columns={columnsMatched}
                  muiTableBodyCellProps={{
                    sx: {
                      backgroundColor: 'white',
                    },
                  }}
                  data={tableRows}
                  enableRowSelection
                  enablePagination={false}
                  getRowId={(row) => row.id}
                  enableColumnResizing
                  enableDensityToggle={false}
                  initialState={{ density: "compact" }}
                  onRowSelectionChange={setRowSelection}
                  state={{ rowSelection }}
                  enableRowVirtualization
                />

                {!tableRows.length ? (
                  <Box mt={3}>
                    <NoResultFound />
                  </Box>
                ) : null}
              </>
            )}
          </TabPanel>
          <TabPanel value="3">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                  <MaterialReactTable
                    muiTableBodyCellProps={{
                      sx: {
                        backgroundColor: 'white',
                      },
                    }}
                    columns={columnsMatched}
                    data={tableRows}
                    enableRowSelection
                    enablePagination={false}
                    enableColumnResizing
                    enableDensityToggle={false}
                    initialState={{ density: "compact" }}
                    getRowId={(row) => row.id}
                    onRowSelectionChange={setRowSelection}
                    state={{ rowSelection }}
                    enableRowVirtualization
                  />

                {!tableRows.length ? (
                  <Box mt={3}>
                    <NoResultFound />
                  </Box>
                ) : null}
              </>
            )}
          </TabPanel>
        </Box>
      </TabContext>
    </MarginWrapper>
  );
};

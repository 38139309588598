import { Table as MuiTable, Paper, TableContainer } from "@mui/material";
import React from "react";

import { TableBody } from "./body";
import { TableHead } from "./head";
import { Data, TableProps } from "./types";

export const Table = <TData extends Data>({
  table,
  selectedRows,
  renderSubComponent,
  nonePaddingColumnIds,
}: TableProps<TData>) => (
  <Paper
    sx={{
      width: "100%",
      mb: 2,
      borderRadius: "8px 8px 0 0",
      boxShadow: "none",
    }}
  >
    <TableContainer style={{ maxHeight: '900px', overflowY: 'auto' }}>

      <MuiTable sx={{ minWidth: 650 }} >
        <TableHead table={table} />
        <TableBody
          table={table}
          selectedRows={selectedRows}
          renderSubComponent={renderSubComponent}
          nonePaddingColumnIds={nonePaddingColumnIds}
        />
      </MuiTable>
    </TableContainer>
  </Paper>
);

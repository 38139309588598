import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useMemo, useState } from "react";

import {
  ConfigParameterDTO,
  clearEnvironment,
  createUserSetting,
  getAbbyySkill,
  getFiscalYearSettings,
  setAbbySkill,
  updateFiscalYear,
} from "api";

import { DatePickerField, DeleteDialog, useDialogControls } from "components";

import { useGetConfigParameter, useNotify } from "hooks";

import { QUERY_KEYS } from "consts";

import { getLoggedUserData, getUTCDateFormat } from "utils";

export const UserPreferencePage = () => {
  const dateFormats = ["MM-DD-YYYY", "DD-MM-YYYY"];
  const loggedUserData = getLoggedUserData();
  const [selectedFormat, setSelectedFormat] = useState("");
  const [fiscalYearMonth, setFiscalYearMonth] = useState("");
  const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const deleteDialogControls: any = useDialogControls();

  const { data: userSettingData, mutateAsync: createDateSetting } =
    useMutation(createUserSetting);

  const notify = useNotify();
  const queryClient = useQueryClient();

  const handleFormatChange = (event: any) => {
    setSelectedFormat(event.target.value);
    if (event.target.value) {
      createDateSetting(
        { dateFormat: event.target.value },
        {
          onError: () =>
            notify.error(`Some error has happen while updating date format`),
          onSuccess: () => {
            notify.success(`Successfully updated date format!`);
            queryClient.invalidateQueries([QUERY_KEYS.USER_SETTINGS]);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (userSettingData) {
      window.sessionStorage.setItem(
        "date_format",
        JSON.stringify(userSettingData?.data?.dateFormat),
      );
    }
  }, [userSettingData]);

  const displayPreferenceModal = () => {
    (window as any)?.displayPreferenceModal();
    return false;
  };

  const handleInputChange = (e: any) => {
    const { target } = e;
    const inputVal = target;
    setInputValue(inputVal.value);
    if (inputVal.value.trim() === "") {
      setError("This field is required.");
    } else {
      setError("");
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (inputValue.trim() === "") {
      setError("This field is required.");
    } else {
      updateAbbyySkills(inputValue, {
        onError: () =>
          notify.error(`Some error has happen while updating Abbyy Skills`),
        onSuccess: () => {
          notify.success(`Successfully updated Abbyy Skills!`);
        },
      });
    }
  };

  const { data } = useGetConfigParameter();

  const getConfigParameterObject = (configData: ConfigParameterDTO[]) =>
    configData.reduce<Record<string, Record<string, string | number>>>(
      (acc, parameter) => {
        acc[parameter.name] = {
          id: parameter.id,
          configValue: parameter.configValue,
        };

        return acc;
      },
      {},
    );

  const configParameter = useMemo(
    () => getConfigParameterObject(data || []),
    [data],
  );

  const { mutateAsync: ClearEnvironment } = useMutation(clearEnvironment);
  const { mutateAsync: updateFiscalYearRequest } =
    useMutation(updateFiscalYear);

  const { data: abbyySkill, mutateAsync: GetAbbyySkill } =
    useMutation(getAbbyySkill);

  const { data: updatedAbbyySkills, mutateAsync: updateAbbyySkills } =
    useMutation(setAbbySkill);

  useEffect(() => {
    if (loggedUserData.superadmin === true) {
      GetAbbyySkill();
    }
  }, [updatedAbbyySkills, GetAbbyySkill]); // eslint-disable-line

  useEffect(() => {
    setInputValue(abbyySkill?.data?.message);
  }, [abbyySkill]);

  useEffect(() => {
    getFiscalYearSettings().then((fiscalYearSettings: any) => {
      const startMonthValue = fiscalYearSettings.find(
        (setting: any) => setting.name === "company_month_start",
      )?.configValue;
      setFiscalYearMonth(startMonthValue);
    });
  }, [fiscalYearMonth, selectedMonth]);

  const onDeleteClick = () => {
    ClearEnvironment()
      .then(() => {
        deleteDialogControls.close();
        notify.success(`${loggedUserData.tenant} environment is deleted!`);
      })
      .catch(() => {
        notify.error(
          `Some error has happen while deleting documents: ${loggedUserData.tenant}!`,
        );
      });
  };

  

  const onMonthChange = (monthValue: any) => {
    if (monthValue !== null) {
      // Update company_month_start parameter
      const startMonthId = configParameter.company_month_start.id as number;
      const endMonthId = configParameter.company_month_end.id as number;
      const endMonth = dayjs(monthValue, "MMM")
        .subtract(1, "month")
        .format("MMM");
      updateFiscalYearRequest([
        {
          id: startMonthId,
          value: monthValue,
        },
        {
          id: endMonthId,
          value: endMonth,
        },
      ])
        .then(() => {
          notify.success(
            `Your fiscal year has been updated to ${monthValue} - ${endMonth}!`,
          );
        })
        .catch(() => {
          notify.error(`Some error has happened while updating!`);
        });
    }
  };

  return (
    <TableContainer component={Box}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" mb={3}>
                <b>TYPE</b>
              </Typography>
              <Typography variant="body1">DATE</Typography>
              <Typography variant="body1" mt={3}>
                CONSENT MANAGEMENT
              </Typography>
              {loggedUserData?.superadmin === true && (
                <Typography variant="body1" mt={3}>
                  CLEAR ENVIRONMENT
                </Typography>
              )}
              {loggedUserData?.superadmin === true && (
                <Typography variant="body1" mt={3}>
                  ABBYY SKILLS
                </Typography>
              )}
              <Typography variant="body1" mt={3}>
                FISCAL YEAR START MONTH
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" mb={3}>
                <b>DEFAULT</b>
              </Typography>
              <Typography variant="body1">
                {userSettingData === undefined
                  ? getUTCDateFormat()
                  : userSettingData?.data?.dateFormat}
              </Typography>
              <Typography variant="body1" mt={3}>
                -
              </Typography>
              {loggedUserData.superadmin === true && (
                <Typography variant="body1" mt={3}>
                  -
                </Typography>
              )}
              {loggedUserData.superadmin === true && (
                <Box mt={3}>
                  <TextField
                    label=""
                    variant="standard"
                    value={inputValue}
                    onChange={handleInputChange}
                    error={!!error}
                    helperText={error}
                    sx={{
                      width: "200px",
                      height: "32px",
                    }}
                  />
                </Box>
              )}
              <Typography variant="body1" mt={3}>
                {fiscalYearMonth !== undefined ? fiscalYearMonth : "JAN"}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" mb={3}>
                <b>CUSTOM PREFERENCE</b>
              </Typography>
              <Select
                defaultValue=""
                fullWidth
                value={selectedFormat}
                onChange={handleFormatChange}
                sx={{
                  width: "200px",
                  height: "32px",
                }}
              >
                {dateFormats.map((format) => (
                  <MenuItem key={format} value={format}>
                    {format}
                  </MenuItem>
                ))}
              </Select>
              <Box mt={3}>
                <Button
                  onClick={displayPreferenceModal}
                  variant="outlined"
                  sx={{
                    color: "black",
                    backgroundColor: "white",
                    width: "200px",
                    height: "32px",
                  }}
                >
                  CONSENT MANAGEMENT
                </Button>
              </Box>
              {loggedUserData.superadmin === true && (
                <Box mt={3}>
                  <Button
                    onClick={deleteDialogControls.open}
                    variant="outlined"
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      width: "200px",
                      height: "32px",
                    }}
                  >
                    CLEAR
                  </Button>
                  <DeleteDialog
                    isOpen={deleteDialogControls.isOpen}
                    onCancel={deleteDialogControls.close}
                    onDeleteClick={() => onDeleteClick()}
                    selectedCount={undefined}
                    target={`${loggedUserData.tenant} Environment`}
                    title={
                      `Delete ${loggedUserData.tenant} Environment?` as string
                    }
                  />
                </Box>
              )}
              {loggedUserData.superadmin === true && (
                <Box mt={3}>
                  <Button
                    onClick={handleSubmit}
                    variant="outlined"
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      width: "200px",
                      height: "32px",
                    }}
                  >
                    APPLY
                  </Button>
                </Box>
              )}
              <Box mt={3}>
                <DatePickerField
                  sx={{
                    maxWidth: "200px"
                  }}
                  views={["month"]}
                  format="MMM"
                  openTo="month"
                  disableHighlightToday
                  onChange={(date) => {
                    const monthValue = date ? date.format("MMM") : null;
                    setSelectedMonth(date);
                    onMonthChange(monthValue);
                  }}
                  value={selectedMonth}
                  clearDate={false}
                  label=""
                />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

import React from "react";
import { Route, Routes } from "react-router-dom";

import { COLORS, RoutedTabs } from "components";

import { useNewBreadcrumb } from "recoils";

import { SETTINGS_ROUTER_V2 } from "./consts ";
import {
  USER_CONSENT_MANAGAMENT_PAGE,
  USER_PREFERENCE_PAGE,
  UserPreferencePage,
} from "./user-preference";
import { ConsentManagament } from "./user-preference/consentmanagament";

const SETTINGS_TABS = [
  {
    label: USER_PREFERENCE_PAGE.TITLE,
    to: USER_PREFERENCE_PAGE.ABSOLUTE_PATH,
  },
];

export const SettingsRouterV2 = () => {
  useNewBreadcrumb(SETTINGS_ROUTER_V2.TITLE);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RoutedTabs sx={{ bgcolor: COLORS.white }} tabs={SETTINGS_TABS} />
        }
      >
        <Route
          path={USER_PREFERENCE_PAGE.PATH}
          element={<UserPreferencePage />}
        />
        <Route
          path={USER_CONSENT_MANAGAMENT_PAGE.PATH}
          element={<ConsentManagament />}
        />
      </Route>
    </Routes>
  );
};

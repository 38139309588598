
import MaterialReactTable from "material-react-table";
import React from "react";
import { Loading } from "components";
import { useEmissionFactors } from "hooks";
import { SCIENTIFIC_UNIT_COLUMNS } from "./components";

export const ScientificUnit = ({ ReferencesData }: { ReferencesData: any }) => {
    const { isLoading } = useEmissionFactors();
    const parsedData = ReferencesData.map((str: string, index: any) => {
        try {
            return JSON.parse(str);
        } catch (error) {
            console.error(`Error parsing string at index ${index}:`, error);
            return null;
        }
    });
    const columns = SCIENTIFIC_UNIT_COLUMNS;
    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <MaterialReactTable
                    columns={columns}
                    muiTableBodyCellProps={{
                        sx: {
                          backgroundColor: 'white',
                        },
                      }}
                    data={parsedData}
                    enableColumnResizing
                    enableDensityToggle={false}
                    initialState={{ density: "compact" }}
                    enablePagination={false}
                    enableRowVirtualization
                />
            )}
        </div>
    );
};
